

export const storeNeighborhoodsArray = [
  { value: 'Evanston', label: 'Evanston' },
  { value: 'Andersonville', label: 'Andersonville' },
  { value: 'Lincoln_Park', label: 'Lincoln Park' },
  { value: 'Wicker_Park', label: 'Wicker Park' },
  { value: '', label: 'Select All' }
];

// Create the array of neighborhood values excluding 'Select All'
export const neighborhoodsValueArray = storeNeighborhoodsArray
  .filter(neighborhood => neighborhood.value !== '')
  .map(neighborhood => neighborhood.value);  
