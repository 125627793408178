import React, { useEffect, useState } from 'react';
import css from './topbar.module.css';
import { useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';

// icons
import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import selectNavImg from '../icons/selectNav.png';
import accountIcon from '../icons/accountDetails.png';
import likesIcon from '../icons/likes.png';
import cartIcon from '../icons/cartIcon.png';
import DropDownMenu from './DropDownMenu';
import { neighborhoodsValueArray, storeNeighborhoodsArray } from '../../../util/storeNeighborhood';
import { useLocation } from 'react-router-dom';
import { mainListingCategories } from '../../../util/mainLisitngCategories';
import { convertUnderscoreToSpace } from '../../../util/convertUnderscoreToSpace';

const category_ids = {
  WOMEN_APPAREL: 'womens_apparel',
  WOMEN_ACCESSORIES: 'womens_accessories',
  MENS_APPAREL: 'mens_apparel',
  MENS_ACCESSORIES: 'mens_accessories',
  HOME: 'home',
};

export const calculateCartCount = cart => {
  let totalCount = 0;
  Object.values(cart || {})?.forEach(item => {
    Object.values(item || {})?.forEach(subItem => {
      totalCount += subItem?.count || 0;
      if (subItem?.variants && subItem?.variants?.length > 0) {
        const variants = subItem?.variants;
        const variantsTotal = variants?.reduce((total, item) => total + (item?.count || 0), 0);
        totalCount += variantsTotal;
      }
    });
  });
  return totalCount;
};

const TopbarSectionComponent = props => {
  const { isAuthenticated, currentUser, config = {} } = props;
  const [keywords, setKeywords] = useState('');
  const [neighborhood, setNeighborhood] = useState([]);
  const history = useHistory();
  const [mounted, setMounted] = useState(false);
  const authenticatedOnClientSide = mounted && isAuthenticated;

  const [hoveringOver, setHoveringOver] = useState('');

  const { cart } = currentUser?.attributes?.profile?.privateData || {};
  const cartCount = calculateCartCount(cart);

  const userType = currentUser?.attributes?.profile?.publicData?.userType || {};
  const isUserCustomer = userType === 'customer';

  const categoryConfiguration = config.categoryConfiguration || {};
  const listingCategories = categoryConfiguration.categories;
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const storeNeighborhoodParams = params.get('storeNeighborhood');
  const keywordsParams = params.get('keywords');

  const notificationDot =
    cartCount > 0 ? <div className={css.notificationDot}>{cartCount}</div> : null;

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    setKeywords(keywordsParams || '');
    setNeighborhood(storeNeighborhoodParams ? storeNeighborhoodParams.split('.') : []);
  }, [location.search]);

  const searchWithKeywords = event => {
    event.preventDefault();
    if (keywords.length === 0) {
      history.push('/s');
    } else {
      history.push(
        neighborhood.length
          ? `/s/?keywords=${keywords}&storeNeighborhood=${neighborhood.join('.')}`
          : `/s/?keywords=${keywords}`
      );
    }
  };

  const getCategories = id => {
    const index = listingCategories.findIndex(category => category.id === id);
    return listingCategories[index];
  };

  const changeNeighborhoodHandler = e => {
    if (!e.target.value) {
      setNeighborhood(e.target.checked ? neighborhoodsValueArray : []);
      return;
    }
    setNeighborhood(
      e.target.checked
        ? [...neighborhood, e.target.value]
        : neighborhood.filter(store => store !== e.target.value)
    );
  };
  const showNeighborhoodText = !neighborhood.length
    ? 'Select neighborhood'
    : neighborhood.length > 1
    ? `${convertUnderscoreToSpace(neighborhood[0])} and more`
    : convertUnderscoreToSpace(neighborhood[0]);


    const localChicagoBoutiques = ' SHOP LOCAL CHICAGO BOUTIQUES & PICK UP IN STORE ';
  return (
    <>
      <div className={css.topbar}>
        <div className={css.topcontainer}>
          <div className={css.tiptop}>
            <div className={css.logocontainer}>
              <a href="/">
                <img
                  src={require('../icons/locagologo.webp')}
                  alt={'Locago logo'}
                  className={css.logo}
                />
              </a>
            </div>
            <div className={css.searchbar}>
              <form onSubmit={searchWithKeywords} className={css.searchForm}>
                <input
                  type="text"
                  placeholder="Search for products or brands"
                  value={keywords}
                  onChange={e => setKeywords(e.target.value)}
                />
                <MagnifyingGlassIcon
                  onClick={searchWithKeywords}
                  disabled={keywords.length === 0}
                  style={{ height: '2vw', width: '30px', color: '#d7d7d7', cursor: 'pointer' }}
                  className={css.searchicon}
                />
              </form>
            </div>

            <div className={css.navSelectBox}>
              <div className={css.navBarSelectBox}>
                <img src={selectNavImg} alt="select" />
                <p> {showNeighborhoodText}</p>
              </div>
              <div className={css.navBarSelectedBox}>
                {storeNeighborhoodsArray.map((storeNeighborhood, index) => {
                  const { value, label } = storeNeighborhood;

                  return (
                    <label
                      key={index}
                      className={
                        neighborhood.includes(value) || neighborhood.length === 4
                          ? css.selected
                          : css.notSelected
                      }
                    >
                      <input
                        type="checkbox"
                        value={value}
                        checked={value ? neighborhood.includes(value) : neighborhood.length === 4}
                        onChange={e => changeNeighborhoodHandler(e)}
                      />
                      <span className={css.checkmark}></span>
                      {label}
                    </label>
                  );
                })}
              </div>
            </div>
            <div className={css.accountsInformation}>
              {authenticatedOnClientSide ? (
                <div className={css.accountMain}>
                  <Link to="/account">
                    <img src={accountIcon} alt="Account" />
                  </Link>
                  <div className={css.dropdownAccount}>
                    <Link to="/orders">Orders</Link>
                    {authenticatedOnClientSide ? (
                      <>{!isUserCustomer ? <Link to={`/listings`}>Listings</Link> : null}</>
                    ) : null}
                    <Link to="/messages">Messages</Link>

                    <Link to="/account">Account settings</Link>

                    {!isUserCustomer ? <Link to="/profile-settings">Profile settings</Link> : null}

                    {authenticatedOnClientSide && (
                      <a
                        onClick={() => {
                          props.onLogout();
                        }}
                      >
                        Log out
                      </a>
                    )}
                  </div>
                </div>
              ) : (
                <div className={css.dropdown}>
                  <Link to="/login" className={css.dropdownHeading}>
                    <img src={accountIcon} alt="Account" />
                  </Link>
                </div>
              )}
              {authenticatedOnClientSide && isUserCustomer ? (
                <>
                  <Link to="/my-likes">
                    <img src={likesIcon} alt="Likes" className={css.likesIcon} />
                  </Link>
                  <Link to="/cart">
                    <img src={cartIcon} alt="carts" />
                    {notificationDot}
                  </Link>
                </>
              ) : null}
            </div>
            <div>
              <div className={css.menuLinesContainer}>
                <div className={css.menuLine}></div>
                <div className={css.menuLine}></div>
                <div className={css.menuLine}></div>
                <nav className={css.menuData}>
                  {mainListingCategories.map(category => {
                    return (
                      <div
                        key={category.id}
                        onMouseEnter={() => setHoveringOver(category.id)}
                        onMouseLeave={() => setHoveringOver('')}
                        className={css.apparelContainer}
                      >
                        <a href={category.link}>{category.label}</a>{' '}
                        <div className={css.appearlDropDown}>
                          {hoveringOver === category.id && (
                            <DropDownMenu column1={getCategories(category.id)} />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.shopLocalBoutiquesContainer}>
        {/* this is for animation SHOP WHITHIN THE NEIGHBORHOOD */}
        <div className={css.scrollAnimateContainer}>
          <div>
            {[...Array(6)].map((_, index) => (
              <h1 key={index}>
                {localChicagoBoutiques.split(' ').map((word, i) => (
                  <span key={i}>{word} </span>
                ))}
              </h1>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
// export default TopbarSectionComponent;

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopbarSectionComponent);
