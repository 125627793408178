/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';
import { isStoreOwner } from '../../../../util/userHelpers';
import { Link } from 'react-router-dom';
import accountIcon from '../../../../containers/LandingPage/icons/accountDetails.png';
import likesIcon from '../../../../containers/LandingPage/icons/likes.png';
import cartIcon from '../../../../containers/LandingPage/icons/cartIcon.png';
import SearchIcon from '../SearchIcon';
import backIcon from '../../../../containers/LandingPage/icons/backArrow.png';
import envelope from '../../../../containers/LandingPage/icons/envelope.svg';

import {
  AvatarLarge,
  ExternalLink,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  Button,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';
import { mainListingCategories } from '../../../../util/mainLisitngCategories';

const CustomLinkComponent = ({ linkConfig, currentPage }) => {
  const { group, text, type, href, route } = linkConfig;
  const getCurrentPageClass = page => {
    const hasPageName = name => currentPage?.indexOf(name) === 0;
    const isCMSPage = pageId => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
    const isInboxPage = tab => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
    const isCurrentPage = currentPage === page;

    return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
      ? css.currentPage
      : null;
  };

  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    // Internal link
    const { name, params, to } = route || {};
    const className = classNames(css.navigationLink, getCurrentPageClass(name));
    return (
      <NamedLink name={name} params={params} to={to} className={className}>
        <span className={css.menuItemBorder} />
        {text}
      </NamedLink>
    );
  }
  return (
    <ExternalLink href={href} className={css.navigationLink}>
      <span className={css.menuItemBorder} />
      {text}
    </ExternalLink>
  );
};

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    customLinks,
    onLogout,
    intl,
    handleMobileSearchOpen,
    isMainMenu,
    setIsMainMenu,
  } = props;

  const userType = currentUser?.attributes?.profile?.publicData?.userType || 'not logged in';
  const isManualInventory = currentUser?.attributes?.profile?.publicData.isManualInventory || false;

  const user = ensureCurrentUser(currentUser);

  const [isAccountSetting, setIsAccountSetting] = useState(false);

  const extraLinks = customLinks.map(linkConfig => {
    return (
      <CustomLinkComponent
        key={linkConfig.text}
        linkConfig={linkConfig}
        currentPage={currentPage}
      />
    );
  });

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';

  const filteredCategoriesLisitng = mainListingCategories.filter(category => category.label !== "Home & Decor")

  return (
    <div className={css.root}>
      <div className={css.accountsInformationContainer}> 
      { isStoreOwner(userType) &&
         <a href="/orders" className={css.envelopeContainer}>
            <div className={css.envelopeMain}>
              <img src={envelope} alt="messges" />
              {notificationCountBadge}
            </div>
          </a>}    
        {isAuthenticated && (
          <div className={css.accountsInformationMain}>
            <span onClick={() => setIsMainMenu(true)}>
              <img src={accountIcon} alt="Account" />
            </span>

            {!isStoreOwner(userType) && (
              <a href="/my-likes">
                <img src={likesIcon} alt="Account" />
              </a>
            )}

            {!isStoreOwner(userType) && (
              <a href="/cart">
                <img src={cartIcon} alt="Account" className={css.cartIcon} />
              </a>
            )}
          </div>
        )}
        <div className={css.mobileNavContainer}>
          <Button
            rootClassName={css.searchMenu}
            onClick={handleMobileSearchOpen}
            title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
          >
            <SearchIcon className={css.searchMenuIcon} />
          </Button>
        </div>
      </div>

      {!isMainMenu && !isAccountSetting ? (
        <div className={css.content}>
          {filteredCategoriesLisitng.map(category => {
            return (
              <div key={category.id} className={css.contentAccessoriesContainer}>
                <a href={category.link}>{category.label}</a>{' '}
              </div>
            );
          })}
          {isAuthenticated ? (
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </InlineTextButton>
          ) : (
            <NamedLink name="LoginPage" className={css.loginLink}>
              <FormattedMessage id="TopbarMobileMenu.loginLink" />
            </NamedLink>
          )}
        </div>
      ) : isMainMenu ? (
        <div className={css.contentAccountMain}>
          <div onClick={() => setIsMainMenu(false)} className={css.backButton}>
            {' '}
            <img src={backIcon} alt="Back" />
            Back{' '}
          </div>
          <div className={css.accountLinksWrapper}>
            <Link
              className={classNames(css.inbox, currentPageClass(`InboxPage:${inboxTab}`))}
              to={`/orders`}
            >
              Orders
            </Link>

            {isStoreOwner(userType) ? (
              <Link
                className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
                to={`/listings`}
              >
                Listings
              </Link>
            ) : null}
            <Link
              className={classNames(css.inbox, currentPageClass(`InboxPage:${inboxTab}`))}
              to="/messages"
            >
              Messages
            </Link>
            <div
              className={css.contentAccessoriesContainer}
              onClick={() => {
                setIsAccountSetting(true);
                setIsMainMenu(false);
              }}
            >
              Account Settings
            </div>
            {isStoreOwner(userType) ? (
              <NamedLink
                className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
                name="ProfileSettingsPage"
              >
                <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
              </NamedLink>
            ) : null}
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </InlineTextButton>
          </div>
          <div className={css.customLinksWrapper}>{extraLinks}</div>
          <div className={css.spacer} />
        </div>
      ) : (
        <div className={css.contentAccountMain}>
          <div
            onClick={() => {
              setIsMainMenu(true);
              setIsAccountSetting(false);
            }}
            className={css.backButton}
          >
            {' '}
            <img src={backIcon} alt="Back" />
            Back{' '}
          </div>
          <div className={css.contentAccountMainData}>
            <div className={css.contentAccessoriesContainer}>
              <a href="/account/contact-details">Contact Details</a>{' '}
            </div>
            <div className={css.contentAccessoriesContainer}>
              <a href="/account/change-password">Password</a>{' '}
            </div>
            {isStoreOwner(userType) ? (
              <div className={css.contentAccessoriesContainer}>
                <a href="/account/payments">Payout details</a>{' '}
              </div>
            ) : (
              <div className={css.contentAccessoriesContainer}>
                <a href="/account/payment-methods">Payment methods</a>{' '}
              </div>
            )}
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </InlineTextButton>
          </div>
        </div>
      )}
      <div className={css.footer}>
        {isStoreOwner(userType) && isManualInventory ? (
          <div className={css.footer}>
            <NamedLink className={css.createNewListingLink} name="NewListingPage">
              <FormattedMessage id="TopbarMobileMenu.newListingLink" />
            </NamedLink>
          </div>
        ) : null}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
};

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
